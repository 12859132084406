import React, {useState, useContext} from 'react'
import Dropzone from "react-dropzone";
import { Carousel, Row, Col } from 'react-bootstrap';
import DragAndDropIcon from '../asset/drag_and_drop_icon.svg';
import $ from 'jquery';

const CreatePostDropzone = (props) => {
    const [dropZoneData,setDropZoneData] = useState({
        fileSelected: false,
        progress: 0,
        imagePath: undefined,
        videoPath: undefined,
        imagesListPath: [],
        fileInfos: [],
    })

    const styles = {
        dragAndDropDisplayContainerStyle: {
            backgroundColor: "#f2f2f2",
            paddingTop: "75px",
            paddingBottom: "75px",
            textAlign: "center",
        },

        titleStyle: {
            marginBottom: "2px"
        },

        descriptionStyle: {
            fontSize: "12px",
            color: "grey",
            marginTop: "5px",
            marginBottom: "5px"
        },

        addMediaButtonStyle: {
            borderRadius: "5px",
            border: "solid 1px",
            borderColor: "#2e80eb",
            display: "inline-block",
            paddingLeft: "10px",
            paddingRight: "10px",
            color: "#2e80eb",
            marginRight: "5px"
        },
    }

    const { postType, onMediaChange } = props;

    const onDrop = (acceptedFiles) => {
        onMediaChange($('#target_media')[0].files[0]);
        if(postType === '101' || postType === '103'){
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()
    
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = (e) => {
                    // Do whatever you want with the file contents
                    const binaryStr = reader.result
                    var image = new Image();
                    image.src = binaryStr;
                    image.onload = function(){
                        let ratio = image.width/image.height;
                        if(postType === '101'){
                            if(ratio <= 2/3 || ratio >= 3/2){
                                window.alert('Tolong pilih gambar dengan rasio 2:3 sampai 3:2');
                            }else{
                                setDropZoneData((prevState) => ({
                                    ...prevState,
                                    imagePath: binaryStr,
                                    fileSelected: true
                                }));
                                onMediaChange(file);
                            }
                        }else if(postType === '103'){
                            if(ratio !== 1){
                                window.alert('Tolong pilih gambar dengan rasio 1:1');
                            }else{
                                let imagesList = dropZoneData.imagesListPath;
                                if(imagesList.length <= 10){
                                    imagesList.push(binaryStr)
                                    setDropZoneData((prevState) => ({
                                        ...prevState,
                                        imagesListPath: imagesList,
                                        fileSelected: true
                                    }));
                                    onMediaChange(file);
                                }else{
                                    window.alert('Jumlah maksimal media kampanye carousel adalah 10 foto');
                                }
                            }
                        }
                    }
                }
                //reader.readAsArrayBuffer(file)
                reader.readAsDataURL(file);
            })
        }else if(postType === '102'){
            let selectedVideoPath = URL.createObjectURL(acceptedFiles[0]);
            const video = document.createElement('video')
            let videoWidth;
            let videoHeight;
            let videoDuration;
            video.onloadedmetadata = evt => {
                // Revoke when you don't need the url any more to release any reference
                URL.revokeObjectURL(selectedVideoPath);
                videoWidth = video.videoWidth;
                videoHeight = video.videoHeight;
                videoDuration = video.duration;
            }
            video.src = selectedVideoPath;
            video.load();
            if(videoDuration > 61){
                window.alert('Durasi maksimal video 1 menit');
            }else{
                if(videoWidth/videoHeight >= 2 || videoWidth/videoHeight <= 2/3){
                    window.alert('Tolong pilih video dengan rasio 2:1 sampai 2:3');
                }else{
                    setDropZoneData((prevState) => ({
                        ...prevState,
                        videoPath: selectedVideoPath,
                        fileSelected: true
                    }));
                    onMediaChange(acceptedFiles[0]);
                }
            }
        }
    }

    const dropZoneClick = (e) => {
        console.log(e);
    }

    const inputClick = (e) => {
        console.log(e)
    }

    const dragAndDropDisplay = <>
        <>
            <div className="myDropZone" style={styles.dragAndDropDisplayContainerStyle}>
                <h5 style={styles.titleStyle}>Add Media</h5>
                <p style={styles.descriptionStyle}>Choose the media for your content.</p>
                <img src={DragAndDropIcon} alt="DragAndDropIcon"></img>
                <p style={styles.descriptionStyle}>Drag and drop your media here</p>
                <Row style={{paddingLeft:"50px",paddingRight:"50px"}} className="no-gutters">
                    <Col>
                        <hr></hr>
                    </Col>
                    <Col md="3">
                        <p style={styles.descriptionStyle}>or</p>
                    </Col>
                    <Col>
                        <hr></hr>
                    </Col>
                </Row>
                <div style={{color:"#2e80eb"}}>
                    <div style={styles.addMediaButtonStyle}>
                        +
                    </div>
                    Add Media
                </div>
            </div>
        </>
    </>;

    return (
        <Dropzone noClick={false} onDrop={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
                <>
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input onClick={inputClick} accept="image/*" onChange={onMediaChange} name={postType} id="target_media" {...getInputProps()} />
                        {dropZoneData.fileSelected ?
                        (
                            postType === '101' ?
                                <div style={{border: "1px solid black", display: "inline-flex", padding: "10px"}}>
                                    <img style={{width: "100%",maxWidth: "fit-content"}} src={dropZoneData.imagePath} alt="preview"></img>
                                </div>
                            : postType === '102' ?
                                <div>
                                    <video src={dropZoneData.videoPath} style={{width: "100%"}} autoPlay loop muted></video>
                                </div>
                            :   <div style={{display: "flex", flexDirection: "row", overflow: "scroll"}}>
                                    {dropZoneData.imagesListPath.map((imagePath,index) => (
                                                <div key={index} style={{border: "1px solid black", display: "inline-flex", padding: "2px", marginRight: "10px"}}>
                                                    <img style={{maxWidth: "300px"}} src={imagePath} alt="preview"></img>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                        ) : (
                            dragAndDropDisplay
                        )}
                    </div>
                </>
            )}
        </Dropzone>
    );
    
}

export default CreatePostDropzone;