import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
  labels: ['Sept 7', 'Sept 8', 'Sept 9', 'Sept 10', 'Sept 11', 'Sept 12','Sept 13'],
  datasets: [
    {
      label: 'Likes',
      data: [500, 500, 200, 400, 500, 200, 400],
      fill: true,
      backgroundColor: 'rgba(102, 204, 255, 0.2)',
      pointBackgroundColor: 'rgba(102, 204, 255,1)',
      borderColor: 'rgba(102, 204, 255, 1)',
      pointHitRadius: 50,
      borderWidth: 1,
      lineTension: 0.3
    },
  ],
};

const options = {
  scales: {
      y: {
          beginAtZero: true,
          max: 700
      },
  },
};

const LineChart = (props) => {

  console.log(props);
  
  return (
    <>
      <Line data={props.data} options={props.options} />
    </>
  );
};

export default LineChart;