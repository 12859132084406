import React from "react";
import { Col, Navbar, Row, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Logo from '../asset/logo_2.png'
import $ from 'jquery';
import UrlHouse from "../UrlHouse";

const DashboardTopBar = () => {
    const navigate = useNavigate();

    const styles = {
        navbarStyle : {
            marginLeft: "50px",
            marginRight: "50px"
        },
        navbarContainerStyle : {
            boxShadow: "0 0px 10px 1px grey",
            position: "fixed",
            width: "100%",
            height: "10%",
            zIndex: "100",
        },
        logoStyle: {
            width: "75px",
            margin: "10px"
        }
    }

    const onLogout = () => {
        let confirm = window.confirm('Logout?')
        if(!confirm) return
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.LOGOUT_SCRIPT),
            data: {x:'y'},
            dataType: "json",
            success: function (response) {
                navigate('/');
            },
            error: function(e){
                navigate('/');
            }
        });
    }

    return (
        <>
            {/* <div style={styles.navbarContainerStyle}>
                    <Row style={{alignItems: "center"}}>
                        <Col md="1" style={{marginRight: "-30px"}}>
                            <img style={styles.logoStyle} alt="woilo" src={Logo}/>
                        </Col>
                        <Col md="2">
                            <h5>Creator Dashboard</h5>
                        </Col>
                    </Row>
            </div> */}
            <Navbar style={styles.navbarContainerStyle}>
                <Navbar.Brand style={styles.navbarStyle}>
                    <img alt="woilo" src={Logo} height="45" style={{marginRight: "10px"}} className="d-inline-block"/>
                    Creator Dashboard
                    <sup style={{color: "red", backgroundColor: "lightgrey", borderRadius: "5px",paddingLeft: "5px", paddingRight: "5px", marginLeft: "5px"}}>
                        BETA
                    </sup>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="ms-auto">
                        <Nav.Link onClick={onLogout} className="align-self-center me-4">
                            <i style={{fontSize: "1.5rem"}} class="fa fa-sign-out" aria-hidden="true"></i>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default DashboardTopBar;