import React, { useState } from "react";
import DashboardTopBar from "./DashboardTopBar";
import { Button, Container, Nav, Row, Col, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from '../asset/logo_2.png'
import $ from 'jquery';

const DashboardNavigation = (props) => {
    const active = true;
    const [ activePostType, setActivePostType ] = useState("");

    const location = useLocation();
    const navigate = useNavigate();

    const styles = {
        sideMenuContainerStyle : {
            backgroundColor: "white",
            position: "fixed",
            top: "10%",
            height: "100%", 
            width: "20%"
        },
        
        sideMenuNavigationContainerStyle : {
            marginTop: "50px"
        },
    
        sideMenuNavigationStyle : {
            paddingLeft: "20px",
            paddingRight: "20px"
        },

        menuContainerStyle: {
            marginTop: "100px",
        },

        menuStyle : {
            marginTop: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "100%",
            borderRadius: "5px",
            backgroundColor: active ?  "rgba(170,170,170,0.5)" : "transparent"
        },

        createPostButtonStyle : {
            paddingTop: "30px",
            paddingBottom: "30px",
            fontSize: "20px",
        },

        popoverContainerStyle : {
            width: "500px",
            maxWidth : "500px"
        },

        popoverPostTypeContainerStyle: {
            paddingTop: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
            textAlign: "center",
            borderRadius: "10px",
            marginTop: "10px",
            border: "solid 1px lightgrey"
        },

        popoverPostContainerHoveredStyle: {
            backgroundColor: "lightgrey"
        }
    }

    const onMouseEnter = (e) => {
        setActivePostType(e.target.className);
    }

    const onPostTypeChoose = (e) => {
        $('#popover_button').trigger('click');
        console.log(location.pathname);
        if(location.pathname !== "/dashboard/content_library"){
            navigate('/dashboard/content_library');
        }
        props.onDrawerOpen();
        props.onPostTypeChange(e.target.className);
    }

    const popover = (
        <Popover style={styles.popoverContainerStyle}>
            <Popover.Header as="h3">Choose content type</Popover.Header>
            <Popover.Body>
                <div className="101" onClick={onPostTypeChoose} onMouseEnter={onMouseEnter} onMouseLeave={() => setActivePostType("")} style={{...styles.popoverPostTypeContainerStyle, ...(activePostType === "101" ? styles.popoverPostContainerHoveredStyle : null)}}>
                    Photo
                </div>
                <div className="102" onClick={onPostTypeChoose} onMouseEnter={onMouseEnter} onMouseLeave={() => setActivePostType("")} style={{...styles.popoverPostTypeContainerStyle, ...(activePostType === "102" ? styles.popoverPostContainerHoveredStyle : null)}}>
                    Video
                </div>
                <div className="103" onClick={onPostTypeChoose} onMouseEnter={onMouseEnter} onMouseLeave={() => setActivePostType("")} style={{...styles.popoverPostTypeContainerStyle, ...(activePostType === "103" ? styles.popoverPostContainerHoveredStyle : null)}}>
                    Carousel
                </div>
            </Popover.Body>
        </Popover>
    );

    return(
        <>
            <DashboardTopBar/>
            <Container className="text-center" style={styles.sideMenuContainerStyle}>
            <div style={styles.sideMenuNavigationContainerStyle}>
                <Nav style={styles.sideMenuNavigationStyle} className="flex-column">
                    {
                        props.accountStatus > 2 ?
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                            <Button id="popover_button" style={styles.createPostButtonStyle}>
                                <i class="fa fa-pencil-square-o" style={{marginRight: "5px"}} aria-hidden="true"></i>
                                Create Post
                            </Button>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Coming soon!</Tooltip>}>
                            <div style={{display: "grid", cursor: "progress"}}>
                                <Button disabled id="popover_button_d" style={styles.createPostButtonStyle}>
                                    <i class="fa fa-pencil-square-o" style={{marginRight: "5px"}} aria-hidden="true"></i>
                                    Create Post
                                </Button>
                            </div>
                        </OverlayTrigger>
                    }
                    <div>
                        <div style={{marginTop: "50px"}}>
                            <Link id="nav_content_library" style={{textDecoration: "none", color: "black",...styles.menuStyle}} to="content_library" href="content_library" name="Content Library" className="d-flex">
                                <Row className="d-flex" style={{alignItems: "center"}}>
                                    <Col md="1">
                                        <i class="fa fa-list-alt" style={{fontSize: "2rem"}} aria-hidden="true"></i>
                                    </Col>
                                    <Col style={{marginLeft: "10px"}}>
                                        <h5>Content Library</h5>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                        {
                            props.accountStatus > 1 ?
                            <div>
                                <Link id="nav_event" style={{textDecoration: "none", color: "black",...styles.menuStyle}} to="event" href="event" name="Event" className="d-flex">
                                    <Row className="d-flex" style={{alignItems: "center"}}>
                                        <Col md="1">
                                            <i class="fa fa-trophy" style={{fontSize: "2rem"}} aria-hidden="true"></i>
                                        </Col>
                                        <Col style={{marginLeft: "10px"}}>
                                            <h5>Event</h5>
                                        </Col>
                                    </Row>
                                </Link>
                            </div>:
                            <div></div>
                        }
                            <Link id="nav_insight" style={{textDecoration: "none", color: "black",...styles.menuStyle}} to="insight" href="insight" name="Insight" className="d-flex">
                                <Row className="d-flex" style={{alignItems: "center"}}>
                                    <Col md="1">
                                        <i class="fa fa-bar-chart" style={{fontSize: "2rem"}} aria-hidden="true"></i>
                                    </Col>
                                    <Col style={{marginLeft: "10px"}}>
                                        <h5>Insight</h5>
                                    </Col>
                                </Row>
                            </Link>
                        <div >
                        </div>
                            <Link id="nav_contact" style={{textDecoration: "none", color: "black",...styles.menuStyle}} to="contact" href="contact" name="Contact Us" className="d-flex">
                                <Row className="d-flex" style={{alignItems: "center"}}>
                                    <Col md="1">
                                        <i class="fa fa-phone" style={{fontSize: "2rem"}} aria-hidden="true"></i>
                                    </Col>
                                    <Col style={{marginLeft: "10px"}}>
                                        <h5>Contact Us</h5>
                                    </Col>
                                </Row>
                            </Link>
                        <div >
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Coming soon!</Tooltip>}>
                                <div style={{display: "grid"}}>
                                    <Link onClick={(e) => {e.preventDefault()}} style={{textDecoration: "none", color: "black",filter: "opacity(0.5)", cursor: "progress",...styles.menuStyle}} to="" href="" name="Schedule" className="d-flex">
                                        <Row className="d-flex" style={{alignItems: "center"}}>
                                            <Col md="1">
                                                <i class="fa fa-calendar" style={{fontSize: "2rem"}} aria-hidden="true"></i>
                                            </Col>
                                            <Col style={{marginLeft: "10px"}}>
                                                <h5>Schedule</h5>
                                            </Col>
                                        </Row>
                                    </Link>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Coming soon!</Tooltip>}>
                                <div style={{display: "grid"}}>
                                    <Link onClick={(e) => {e.preventDefault()}} style={{textDecoration: "none", color: "black",filter: "opacity(0.5)", cursor: "progress",...styles.menuStyle}} to="" href="" name="Schedule" className="d-flex">
                                        <Row className="d-flex" style={{alignItems: "center"}}>
                                            <Col md="1">
                                                <i class="fa fa-money" style={{fontSize: "2rem"}} aria-hidden="true"></i>
                                            </Col>
                                            <Col style={{marginLeft: "10px"}}>
                                                <h5>Monetize</h5>
                                            </Col>
                                        </Row>
                                    </Link>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                    
                </Nav>
            </div>
        </Container>
        </>
    )
}

export default DashboardNavigation;