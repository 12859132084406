import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container, Spinner } from 'react-bootstrap';
import LineChart from '../components/LineChart';
import UrlHouse from '../UrlHouse';
import $ from 'jquery';

const InsightPage = () => {
    const [ insightData, setInsightData ] = useState({
        followerCount : null,
        popularity: null,
        totalLikes : null,
        totalComments: null
    });

    const [ chartLikeData, setChartLikeData ] = useState({
        data: {
            labels: ['-', '-', '-', '-', '-', '-','-'],
            datasets: [
                {
                label: 'Likes',
                data: [0, 0, 0, 0, 0, 0, 0],
                fill: true,
                backgroundColor: 'rgba(102, 204, 255, 0.2)',
                pointBackgroundColor: 'rgba(102, 204, 255,1)',
                borderColor: 'rgba(102, 204, 255, 1)',
                pointHitRadius: 50,
                borderWidth: 1,
                lineTension: 0.3
                },
            ],
        },
        option: {
            scales: {
                y: {
                    beginAtZero: true,
                    max: 700
                },
            },
        }
    })

    const [ chartCommentData, setChartCommentData ] = useState({
        data: {
            labels: ['-', '-', '-', '-', '-', '-','-'],
            datasets: [
                {
                label: 'Comments',
                data: [0, 0, 0, 0, 0, 0, 0],
                fill: true,
                backgroundColor: 'rgba(102, 204, 255, 0.2)',
                pointBackgroundColor: 'rgba(102, 204, 255,1)',
                borderColor: 'rgba(102, 204, 255, 1)',
                pointHitRadius: 50,
                borderWidth: 1,
                lineTension: 0.3
                },
            ],
        },
        option: {
            scales: {
                y: {
                    beginAtZero: true,
                    max: 700
                },
            },
        }
    })

    useEffect(() => {
      loadInsight();
    }, []);
    

    const styles = {
        cardStyle: {
            padding: "20px 30px",
            marginBottom: "30px",
            borderRadius: "25px"
        },

        pageTitleStyle : {
            marginBottom: "25px"
        },
    }

    const handleChartData = (likesDataR,commentsDataR) => {
        var maxLike = 0;
        var maxComment = 0;
        var dataLike = {
            labels: [],
            series: [
                []
            ]
        };
        var dataComment = {
            labels: [],
            series: [
                []
            ]
        };

        let likesData = Object.keys(likesDataR).map((key) => [key, likesDataR[key]]);
        let commentsData = Object.keys(commentsDataR).map((key) => [key, commentsDataR[key]]);

        for(let i=0;i<likesData.length;i++){
            let time = likesData[i][0].slice(4,6)+'/'+likesData[i][0].slice(6,8);
            dataLike.series[0].push(likesData[i][1]);
            dataLike.labels.push(time);
            if(likesData[i][1] > maxLike){
                maxLike = likesData[i][1];
            }
        }

        setChartLikeData({
            ...chartLikeData,
            data: {
                labels: dataLike.labels.length === 0 ? chartLikeData.data.labels : dataLike.labels,
                datasets: [
                    {
                        ...chartLikeData.data.datasets[0],
                        data: dataLike.series[0],
                    },
                ]
            },
            option: {
                scales: {
                    y: {
                        ...chartLikeData.option.scales.y,
                        max: maxLike === 0 ? 100 : maxLike
                    }
                }
            }
        })

        for(let i=0;i<commentsData.length;i++){
            let time = commentsData[i][0].slice(4,6)+'/'+commentsData[i][0].slice(6,8);
            dataComment.series[0].push(commentsData[i][1]);
            dataComment.labels.push(time);
            if(commentsData[i][1] > maxComment){
                maxComment = commentsData[i][1];
            }
        }

        setChartCommentData({
            ...chartCommentData,
            data: {
                labels: dataComment.labels.length === 0 ? chartCommentData.labels : dataComment.labels,
                datasets: [
                    {
                        ...chartCommentData.data.datasets[0],
                        data: dataComment.series[0],
                    },
                ]
            },
            option: {
                scales: {
                    y: {
                        ...chartCommentData.option.scales.y,
                        max: maxComment === 0 ? 100 : maxComment
                    }
                }
            }
        })

    }

    const loadInsight = () => {
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.LOAD_INSIGHT),
            data: {a: 'b'},
            dataType: "json",
            success: function (response) {
                console.log(response);
                setInsightData({
                    followerCount : response.follower_count,
                    popularity: response.popularity,
                    totalLikes : response.total_likes,
                    totalComments: response.total_comments
                })
                handleChartData(response.likes_data, response.comments_data);
            },
            error: function(e) {
                console.warn(e)
            }
        });
    }

    return(
        <>
            <h4 style={styles.pageTitleStyle}>Insight</h4>
            <Card style={styles.cardStyle}>
                <Row>
                    <Col>
                        <div>
                            <h5>Followers</h5>
                            <p>{
                                insightData.followerCount != null ?
                                insightData.followerCount :
                                <Container>
                                    <Spinner animation="grow" style={{marginRight: "5px"}} size="sm"/>
                                    Loading...
                                </Container> 
                            }</p>
                        </div>
                        <div>
                            <h5>Popularity</h5>
                            <p>{
                                insightData.popularity != null ?
                                insightData.popularity :
                                <Container>
                                    <Spinner animation="grow" style={{marginRight: "5px"}} size="sm"/>
                                    Loading...
                                </Container> 
                            }</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <h5>Total likes</h5>
                            <p>{
                                insightData.totalLikes != null ?
                                insightData.totalLikes :
                                <Container>
                                    <Spinner animation="grow" style={{marginRight: "5px"}} size="sm"/>
                                    Loading...
                                </Container> 
                            }</p>
                        </div>
                        <div>
                            <h5>Total comments</h5>
                            <p>{
                                insightData.totalComments != null ?
                                insightData.totalComments :
                                <Container>
                                    <Spinner animation="grow" style={{marginRight: "5px"}} size="sm"/>
                                    Loading...
                                </Container> 
                            }</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LineChart data={chartLikeData.data} option={chartLikeData.option}/>
                    </Col>
                    <Col>
                        <LineChart data={chartCommentData.data} option={chartCommentData.option}/>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default InsightPage;