import logo from './asset/logo.svg';
import './css/App.css';
import CreatorDashboardPage from './pages/CreatorDashboard';
import LoginPage from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContentLibraryPage from "./pages/ContentLibraryPage";
import SchedulePage from "./pages/SchedulePage";
import InsightPage from "./pages/InsightPage";
import EventPage from "./pages/EventPage";
import React, { useState } from 'react';
import ContactPage from './pages/ContactPage';

function App() {
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ postTypeCreate, setPostTypeCreate ] = useState("");
  const [ drawerType, setDrawerType ] = useState(0);

  const onDrawerClose = () => {
    let confirm = drawerType === 0 ? window.confirm('Cancel post creation? All changes will be lost') : true;
    if(confirm){
        setDrawerOpen(false);
    }
  }

  const onDrawerOpen = () => {
    setDrawerOpen(true);
    setDrawerType(0);
  }

  const onPostDetailOpen = () => {
    setDrawerOpen(true);
    setDrawerType(1);
  }

  const onPostTypeChange = (postType) => {
    setPostTypeCreate(postType);
  }

  const onPostCreateFinish = () => {

  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage/>}/>
        <Route path="/dashboard" element={<CreatorDashboardPage onDrawerOpen={onDrawerOpen} onPostTypeChange={onPostTypeChange}/>}>
          <Route path="content_library" element={<ContentLibraryPage onPostDetailOpen={onPostDetailOpen} drawerOpen={drawerOpen} onDrawerClose={onDrawerClose} postTypeCreate={postTypeCreate}/>}/>
          <Route path="schedule" element={<SchedulePage/>}/>
          <Route path="insight" element={<InsightPage/>}/>
          <Route path="event" element={<EventPage/>}/>
          <Route path="contact" element={<ContactPage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
