import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SchedulePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate(-1);
    }, []);
    
    return(
        <>
        </>
    )
}

export default SchedulePage;