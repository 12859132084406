import React,{ useState, useEffect } from "react";
import LoginImage from '../asset/login_asset1.svg';
import { Container, Col, Row, Button, Form} from 'react-bootstrap';
import { useHistory, useNavigate, useRouteMatch } from 'react-router-dom';
import LogoFull from '../asset/logo_full.svg';
import $ from "jquery";
import UrlHouse from "../UrlHouse";

const LoginPage = () => {
    const [loginFormData, setLoginFormData] = useState({
        email: "",
        password: ""
    });
    const styles ={
        imageBackground : {
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize : "cover",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            display: "flex",
        
            position: "absolute",
            width: "50%",
            height: "100%",
            left: "0px",
            top: "0px",
            paddingLeft: "150px",
            paddingRight: "150px"
        },

        signInStyle : {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "absolute",
            width: "50%",
            height: "100%",
            right: "0px",
            top: "0px",
            backgroundColor: "white",
        },
    
        titleStyle : {
            color: "white"
        },
    
        descriptionStyle : {
            color: "white",
            marginTop: "30px"
        },
    
        leftInnerContainerStyle : {
            justifyContent : "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
    
        rightTitleStyle : {
            marginTop: "100px",
            marginBottom: "15px"
        },
        
        rightDescriptionStyle : {
            color : "grey",
            marginBottom: "30px"
        },
        
        signInButtonStyle : {
            width : "100%",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
    
        formStyle: {
            paddingLeft: "25%",
            paddingRight: "25%"
        },
    
        formLabelStyle: {
            fontSize: "16px"
        },
        
        signUpStyle: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        }
    }

    useEffect(() => {
        checkSession();
    }, [])

    const navigate = useNavigate();

    const signInButtonClick = (e) => {
        handleLogin();
    }

    const handleLogin = () => {
        if(loginFormData.email !== "" && loginFormData.password !== ""){
            // Call API
            $.ajax({
                type: "POST",
                url: new UrlHouse().getApiUrl(UrlHouse.LOGIN_SCRIPT),
                data: {
                    param101: loginFormData.email,
                    param102: loginFormData.password
                },
                dataType: "json",
                success: function (response) {
                    console.log(response);
                    if(response.result === 0){
                        navigate('/dashboard/content_library');
                    }else if(response.result === 1){
                        window.alert("E-mail/username atau password yang Anda masukkan salah");
                    }else if(response.result === 2){
                        window.alert("E-mail/username yang Anda masukkan tidak terdaftar");
                    }else{
                        window.alert("Terjadi kesalahan saat masuk ke Woilo Creator Dashboard, coba lagi nanti");
                    }
                },
                error: function(e){
                    console.warn(e)
                }
            });
        }else{
            window.alert('Tolong lengkapi Email & Password')
        }
    }

    const checkSession = () => {
        // $.ajax({
        //     type: "POST",
        //     url: new UrlHouse().getApiUrl(UrlHouse.CHECK_USER_DATA),
        //     data: {x:'y'},
        //     dataType: "json",
        //     success: function (response) {
        //         console.log(response);
        //         if(response.result === 1){
        //             history.push(`${path}/dashboard`);
        //         }
        //     },
        //     error: function(e){
        //         console.warn(e);
        //     }
        // });
    }

    const handleLoginFormChange = (e) => {
        setLoginFormData({...loginFormData, [e.target.name] : e.target.value})
    }

    return(
        <>
        <main className="sectionPadding" onKeyPress={(e) => {if(e.key === 'Enter'){signInButtonClick()}}}>
            <Row>
                <Col md="6" className="align-self-center">
                    <div style={styles.imageBackground}>
                        <Container>
                            <h1 style={styles.titleStyle} className="text-left"><b>Hello!</b></h1>
                            <h1 style={styles.titleStyle} className="text-left"><b>Welcome to WoiLo Creator Dashboard</b></h1>
                            <p style={styles.descriptionStyle}>WoiLo is a social media platform that offers users the ability to earn money, the more time you spend on site, the more money you can earn.</p>
                        </Container>
                    </div>
                </Col>
                <Col md="6" className="align-self-center">
                        <div style={styles.signInStyle}>
                            <Form style={styles.formStyle}>
                                <Container style={styles.leftInnerContainerStyle} className="align-self-center">
                                    <img className="text-center"alt="woilo"src={LogoFull}/>
                                    <h2 style={styles.rightTitleStyle}>Masuk</h2>
                                    <p style={styles.rightDescriptionStyle}>Log in to WoiLo Creator Dashboard</p>
                                    
                                </Container>    
                                <Container>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label style={styles.formLabelStyle}>Email Address / Username</Form.Label>
                                        <Form.Control onChange={handleLoginFormChange.bind(this)} size="lg" name="email" type="email" placeholder="Email" />
                                    </Form.Group>
                                </Container>
                                
                                <Container>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label style={styles.formLabelStyle}>Password</Form.Label>
                                        <Form.Control onChange={handleLoginFormChange.bind(this)} size="lg" name="password" type="password" placeholder="Password" />
                                    </Form.Group>
                                </Container>
                                
                                <Container className="text-center mb-3">
                                    <Button onClick={signInButtonClick.bind(this)} variant="primary" style={styles.signInButtonStyle}>
                                        Masuk
                                    </Button> 
                                </Container>
                                
                            </Form>
                        </div>
                </Col>
            </Row>
        </main>
        </>
    );
}

export default LoginPage;