import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, ToggleButton, FormCheck, Carousel, Container, Spinner } from 'react-bootstrap';
import CreatePostDropzone from './CreatePostDropzone';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from "react-modern-calendar-datepicker";
import TimePicker from 'react-time-picker'
import UrlHouse from '../UrlHouse';
import Logo from '../asset/logo_2.png';
import $ from 'jquery';
import {encode as base64_encode} from 'base-64';


const SideDrawer =  (props) => {
    const [ createPostData, setCreatePostData ] = useState({
        caption: "",
        imageMedia: [],
        videoMedia: undefined,
        allowRepost: true,
        enableComments: true
    })
    const [ selectedDay, setSelectedDay ] = useState(null);
    const [ scheduleDate, setScheduleDate ] = useState({
        date: null,
        hour: new Date()
    });
    const [ draftChecked, setDraftChecked ] = useState(false);
    const [ scheduleChecked,setScheduleChecked ] = useState(false);
    const [ createPostMode, setCreatePostMode ] = useState("");
    const [ postDetailData, setPostDetailData ] = useState(null);

    useEffect(() => {
        console.log(420);
        if(props.drawerType === 1){
            getPostDetail(props.postDetailId);
        }
    }, [props.drawerType,props.postDetailId]);
    

    const styles = {
        drawerContainerStyle: {
            height: "100%",
            background: "white",
            position: "fixed",
            top: "0",
            right: "0",
            width: "75%",
            zIndex: "999",
            boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",
            transform: "translateX(100%)",
            transition: "transform 0.3s ease-out",
            padding: "30px",
            overflow: "scroll"
        },

        postMediaContainer: {
            padding: "10px",
            marginBottom: "20px"
        },

        lightgreyBorder: {
            border: "1px solid lightgrey",
        }
    }

    const { isOpen } = props;

    const handleCaptionChange = (e) => {
        let caption = e.target.value;
        setCreatePostData({...createPostData, caption: caption});
    }

    const handleDateChange = (e) => {
        console.log(e);
        console.log(typeof e);
        if(typeof e === "string"){
            setScheduleDate({...scheduleDate, hour : e});
        }else{
            setScheduleDate({...scheduleDate, date: e});
        }
    }

    const handleMediaChange = (file) => {
        let postType = props.postTypeCreate;
        if(postType === "101"){
            let imageList = [];
            imageList.push(file);
            setCreatePostData({...createPostData, imageMedia: imageList})
        }else if(postType === '103'){
            let imageList = [...createPostData.imageMedia]
            imageList.push(file);
            setCreatePostData({...createPostData, imageMedia: imageList})
        }else if(postType === '102'){
            setCreatePostData({...createPostData, videoMedia: file})
        }
    }

    const uploadMedia = () => { 
        let postType = props.postTypeCreate;
        let postImageMedia = createPostData.imageMedia;
        let postVideoMedia = createPostData.videoMedia;
        if(createPostMode !== "3"){
            let e  = scheduleDate;
            let split = e.hour.split(':');
            let hour = `${split[0]}${split[1]}${split[2]}`;
            let year = e.date.year.toString();
            let month = e.date.month.toString();
            let day = e.date.day.toString();
            let date = `${year}${month.length === 1 ? `0${month}` : month}${day.length === 1 ? `0${day}` : day}`;
            let timeStamp = `${date}${hour}000000`;
            console.log('timeStamp',timeStamp);
            return
        }
        if(postType === '101'){
            postImageMedia.forEach(file => {
                let imageFormData = new FormData();
                imageFormData.append('file',file);
                $.ajax({
                    url: new UrlHouse().getApiUrl(UrlHouse.UPLOAD_IMAGE_MEDIA),
                    type: "POST",
                    data:  imageFormData,
                    contentType: false,
                    cache: false,
                    processData:false,
                    dataType: "json",
                    success: function(response){
                        console.log(response);
                        if(response.result === 3){
                            // Success
                            // setMediaUploaded(true);
                            // addCampaignContext.updateCampaignDataValue('campaignImageUrl',response.image_url);
                            // addCampaignContext.nextPage();
                            // history.push(`./step3`);
                            let imageUrl = JSON.stringify([response.image_url]);
                            savePost(imageUrl,"");
                        }else if(response.result === 2){
                            // Failed
                            window.alert('Gagal mengunggah media iklan, mohon coba beberapa saat lagi.')
                        }else if(response.result === 1){
                            // Failed
                            window.alert('Terjadi kesalahan, mohon coba beberapa saat lagi.')
                        }else{
                            // Forbiddenn
                            window.alert('ERROR: 403');
                        }
                    },error: function(e){
                        console.log(e)
                    }
                });
            })
        }else if(postType === '103'){
            //addCampaignContext.updateCampaignDataValue('campaignImageUrl',JSON.stringify(tmp_imageUrl).replace(/\"/g,'\\"'))
            let total = postImageMedia.length;
            let tmp_imageUrl = [];
            postImageMedia.forEach(file => {
                let imageFormData = new FormData();
                imageFormData.append('file',file);
                $.ajax({
                    url: new UrlHouse().getApiUrl(UrlHouse.UPLOAD_IMAGE_MEDIA),
                    type: "POST",
                    data:  imageFormData,
                    contentType: false,
                    cache: false,
                    processData:false,
                    dataType: "json",
                    success: function(response){
                        console.log(response);
                        if(response.result === 3){
                            // Success
                            tmp_imageUrl.push(response.image_url);
                            console.log(`uploaded ${tmp_imageUrl.length}`);
                            if(tmp_imageUrl.length === total){
                                // setMediaUploaded(true);
                                // addCampaignContext.updateCampaignDataValue('campaignImageUrl',JSON.stringify(tmp_imageUrl).replace(/\"/g,'\\"'))
                                // addCampaignContext.nextPage();
                                // history.push(`./step3`);
                                let imageUrl = JSON.stringify(tmp_imageUrl);
                                savePost(imageUrl,"");
                            }
                        }else if(response.result === 2){
                            // Failed
                            window.alert('Gagal mengunggah media iklan, mohon coba beberapa saat lagi.')
                        }else if(response.result === 1){
                            // Failed
                            window.alert('Terjadi kesalahan, mohon coba beberapa saat lagi.')
                        }else{
                            // Forbidden
                            window.alert('ERROR: 403');
                        }
                    },error: function(e){
                        console.log(e)
                    }
                });
            })
        }else if(postType === '102'){
            let videoFormData = new FormData();
            videoFormData.append('file', postVideoMedia);
            $.ajax({
                url: new UrlHouse().getApiUrl(UrlHouse.UPLOAD_VIDEO_MEDIA),
                method:"POST",
                contentType:false,
                processData: false,
                data: videoFormData,
                async:true,
                xhr: function(){
                    $('#progress_bar_wrap').show();
                    if(window.XMLHttpRequest){
                        var xhr = new window.XMLHttpRequest();
                        xhr.upload.addEventListener("progress", function(evt){
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total;
                                $('#progressBar').css('display','flex');
                                $('#progressBar').attr('value',percentComplete*100);
                            }
                        }, false);
                    }
                    return xhr;
                },
                success:function(response){
                    let videoUploadResponse = JSON.parse(response);
                    if(videoUploadResponse.result === '3'){
                        window.alert('uploaded');
                        // setMediaUploaded(true);
                        // addCampaignContext.updateCampaignDataValue("campaignVideoUrl",videoUploadResponse.video_url);
                        // addCampaignContext.nextPage();
                        // history.push(`./step3`);
                        savePost(response.image_url,videoUploadResponse.video_url)
                    }else if(videoUploadResponse.result === '2'){
                        window.alert('Gagal mengunggah media iklan, mohon coba beberapa saat lagi.')
                    }else if(videoUploadResponse.result === '1'){
                        window.alert('Terjadi kesalahan, mohon coba beberapa saat lagi.')
                    }else{
                        window.alert('ERROR 403');
                    }
                },
                error: function(e){
                    console.warn(e);
                }
            });
        }
    }

    const savePost = (imageUrl,videoUrl) => {
        console.log(createPostMode);
        if(createPostMode === "1"){
            // Schedule
            console.log(scheduleDate);
            let confirm = window.confirm("Schedule post?");
            if(!confirm) return
            $.ajax({
                type: "POST",
                url: new UrlHouse().getApiUrl(UrlHouse.SAVE_POST_SCHEDULE),
                data: {
                    post_schedule: "",
                    post_picture: imageUrl,
                    post_video: videoUrl,
                    post_description: createPostData.caption,
                    link: "",
                    comment_disabled: createPostData.enableComments ? "0" : "1",
                    allow_repost: createPostData.allowRepost ? "1" : "0",
                    post_type: props.postTypeCreate
                },
                dataType: "json",
                success: function (response) {
                    
                },
                error: function(e) {
                    console.warn(e)
                }
            });
        }else if(createPostMode === "2"){
            // Draft

        }else if(createPostMode === "3"){
            // Publish
            if(createPostData.imageMedia.length === 0){
                window.alert('Please pick a media before publishing');
            }else{
                let confirm = window.confirm('Publish post?');
                if(!confirm) return
                $.ajax({
                    type: "POST",
                    url: new UrlHouse().getApiUrl(UrlHouse.PUBLISH_POST),
                    data: {
                        post_picture: imageUrl,
                        post_video: videoUrl,
                        post_description: createPostData.caption,
                        link: "",
                        comment_disabled: createPostData.enableComments ? "0" : "1",
                        allow_repost: createPostData.allowRepost ? "1" : "0",
                        post_type: props.postTypeCreate
                    },
                    success: function (response) {
                        console.log(response);
                        window.alert("Post successfully published");
                        window.location.reload();
                    },
                    error: function(e) {
                        console.warn(e);
                    }
                });
            }
            

        }else{
            // Error

        }
    }

    const createPostDrawerDisplay = (
        <div style={{...styles.drawerContainerStyle,...(isOpen ? {transform: "translateX(0)"} : null)}}>
                <h1>Create {props.postTypeCreate === "101" ? "Photo" : props.postTypeCreate === "102" ? "Video" : "Carousel"} Content</h1>
                <hr></hr>
                <Row>
                    <Col sm="8">
                        <h3>Caption</h3>
                        <div style={{position: "relative"}}>
                            <Form.Control
                                    onChange={handleCaptionChange}
                                    as="textarea"
                                    placeholder="Write your caption here ..."
                                    style={{ height: '40vh', width: "100%", marginBottom: "10px", padding: "10px" }}
                            />
                            <div style={{position: "absolute",bottom: "10px",right: "15px", display: "flex"}}>
                                <FormCheck 
                                    id="enableComment"
                                    type="switch"
                                    checked={createPostData.enableComments}
                                    onChange={() => {setCreatePostData(!createPostData.enableComments)}}
                                    label="Enable Comments"
                                    style={{marginRight: "10px"}}
                                />
                                <FormCheck 
                                    id="allowRepost"
                                    type="switch"
                                    checked={createPostData.allowRepost}
                                    onChange={() => {setCreatePostData(!createPostData.allowRepost)}}
                                    label="Allow Repost"
                                />
                            </div>
                        </div>
                        <div style={{border: "1px solid lightgrey", padding: "20px"}}>
                            <CreatePostDropzone postType={props.postTypeCreate} onMediaChange={handleMediaChange}/>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div>
                            <h3>Schedule</h3>
                            {/* <Form.Control onChange={() => {console.log('anhay')}} id="postCreateSchedule" type="date" name='postCreateSchedule'/> */}
                            <Calendar
                                id="date_picker"
                                value={scheduleDate.date}
                                onChange={handleDateChange}
                                shouldHighlightWeekends
                            />
                            <div style={{marginTop: "10px", display: "flex", alignItems: "baseline"}}>
                                <p>Time:</p>
                                <div style={{marginLeft: "10px"}}>
                                    <TimePicker
                                        style={{width:"inherit"}}
                                        amPmAriaLabel="Select AM/PM"
                                        clearAriaLabel="Clear value"
                                        clockAriaLabel="Toggle clock"
                                        hourAriaLabel="Hour"
                                        maxDetail="second"
                                        minuteAriaLabel="Minute"
                                        nativeInputAriaLabel="Time"
                                        id="hour_picker"
                                        onChange={handleDateChange}
                                        secondAriaLabel="Second"
                                        value={scheduleDate.hour}
                                    />
                                </div>
                            </div>
                            <ToggleButton
                                className="mb-2"
                                type="checkbox"
                                variant="outline-primary"
                                checked={createPostMode === "1"}
                                value="1"
                                onClick={() => setCreatePostMode("1")}
                            >
                                Schedule post
                            </ToggleButton>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <h3>
                                Draft
                            </h3>
                            <p>
                                Save the post as draft to review again later
                            </p>
                            <ToggleButton
                                className="mb-2"
                                type="checkbox"
                                variant="outline-primary"
                                checked={createPostMode === "2"}
                                value="2"
                                onClick={() => setCreatePostMode("2")}
                            >
                                Save as draft
                            </ToggleButton>

                        </div>
                        <div style={{marginTop: "10px"}}>
                            <h3>
                                Publish
                            </h3>
                            <p>
                                Publish the post to your WoiLo account
                            </p>
                            <ToggleButton
                                className="mb-2"
                                type="checkbox"
                                variant="outline-primary"
                                checked={createPostMode === "3"}
                                value="3"
                                onClick={() => setCreatePostMode("3")}
                            >
                                Publish now
                            </ToggleButton>
                        </div>
                        <Row style={{marginTop: "10px"}}>
                            <Col>
                                <Button style={{width: "inherit"}}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button onClick={uploadMedia} style={{width: "inherit"}} disabled={createPostMode === ""}>Save</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
    );

    const getPostDetail = (postId) => {
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.POST_DETAIL),
            data: {
                post_id: postId
            },
            dataType: "json",
            success: function (response) {
                console.log(response);
                let r = response;
                setPostDetailData({
                    postId: r.post_id,
                    commentCount: r.comment_count,
                    likeCount: r.like_count,
                    postDescription: r.post_description,
                    postLink: r.post_link,
                    postPicture: r.post_picture,
                    postType: r.post_type,
                    postVideo: r.post_video,
                    timeStamp: r.time_stamp,
                    commentDisabled: r.comment_disabled,
                    allowRepost: r.allow_repost
                })
            },
            error: function(e){
                console.warn(e)
            }
        });
    }

    const postMedia = (postType,postPicture,postVideo) => {
        console.log(postType);
        console.log(postPicture);
        console.log(postVideo);
        if(postType === "101"){
            return <img style={{width: "100%"}} src={`https://sestyc.com/sestyc/content_file/${postPicture}`} alt="postPicture"></img>
        }else if(postType === "102"){
            return <video controls style={{width: "100%"}} src={`https://sestyc.com/sestyc/content_file_video/${postVideo}`}></video>
        }else if(postType === "103"){
            let postPictures = JSON.parse(postPicture);
            console.log(postPictures);
            let carousel = <Carousel>
                {postPictures.map((imagePath,index) => (
                            <Carousel.Item interval={null} key={index}>
                                <img style={{width: "100%"}} src={`https://sestyc.com/sestyc/content_file/${imagePath}`} alt={`media_${postDetailData.postId}_${index}`}></img>
                            </Carousel.Item>
                        )
                    )
                }
            </Carousel>
            return carousel;
        }
    }

    const postDetailDrawerDisplay = (
        <div style={{...styles.drawerContainerStyle,...(isOpen ? {transform: "translateX(0)"} : null)}}>
            <h1>Post details</h1>
            <hr></hr>
            <Row>
                {
                    postDetailData !== null ?
                    <>
                        <Col sm="6">
                            <div style={styles.postMediaContainer}>
                                {
                                    postMedia(postDetailData.postType,postDetailData.postPicture,postDetailData.postVideo)
                                }
                            </div>
                        </Col>
                        <Col sm="6">
                            <h5>Caption</h5>
                            <div style={{...styles.lightgreyBorder, padding: "10px", marginBottom: "10px"}}>
                                <p style={{marginBottom: "0px"}}>
                                    {postDetailData.postDescription}
                                </p>
                            </div>
                            <h5>
                                Link
                            </h5>
                            <div style={{...styles.lightgreyBorder, padding: "10px"}}>
                                <p style={{marginBottom: "0px"}}>
                                    {postDetailData.postLink}
                                </p>
                            </div>
                            <h6 style={{marginTop:"10px"}}>
                                Comments {postDetailData.commentDisabled === 1 ? "disabled" : "enabled"}
                            </h6>
                            <h6 style={{marginTop:"10px"}}>
                                Repost {postDetailData.allowRepost === 1 ? "enabled" : "disabled"}
                            </h6>
                            <h6 style={{marginTop:"10px"}}>
                                <i class="fa fa-heart" aria-hidden="true" style={{marginRight: "5px"}}></i>
                                {postDetailData.likeCount} Likes
                            </h6>
                            <h6 style={{marginTop:"10px"}}>
                                <i class="fa fa-commenting" aria-hidden="true" style={{marginRight: "5px"}}></i>
                                {postDetailData.commentCount} Comments
                            </h6>
                        </Col>
                    </>
                    :
                    <Container>
                        <Spinner animation="grow" size="lg"/>
                    </Container> 
                }
            </Row>
        </div>
    );

    return(
        <>
            {
                props.drawerType === 1 ?
                postDetailDrawerDisplay:
                createPostDrawerDisplay
            }
        </>
    );

}

export default SideDrawer;