import React, { useState } from "react";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import $ from 'jquery';
import UrlHouse from '../UrlHouse';

const ContactPage = () => {
    const [feedbackMessage,setFeedbackMessage] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");

    const styles = {
        cardStyle: {
            padding: "20px 30px",
            marginBottom: "30px",
            borderRadius: "25px"
        },

        pageTitleStyle : {
            marginBottom: "25px"
        },

        helpOuterContainerStyle: {
            marginTop: "30px",
            paddingRight: "100px"
        },

        separatorStyle: {
            marginTop: "50px",
            marginBottom: "50px"

        },

        buttonStyle : {
            width: "100%",
        }
    }

    const sendInquiry = () => {
        if(feedbackMessage.length === 0 || phoneNumber.length === 0){
            window.alert('Tolong lengkapi data formulir')
        }else{
            $.ajax({
                type: "POST",
                url: new UrlHouse().getApiUrl(UrlHouse.SUBMIT_MESSAGE),
                data: {message:feedbackMessage,nomor:phoneNumber},
                dataType: "json",
                success: function (response) {
                    if(response.result === 1){
                        window.alert('Inquiry succesfully sent');
                        window.location.reload();
                    }else{
                        window.alert('A problem has occured, please try again later');
                    }
                },
                error: function(e){
                    console.warn(e);
                }
            });
        }
    }

    const handleFeedbackChange = (e) => {
        setFeedbackMessage(e.target.value);
    }

    const handleNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    }

    return (
        <>
            <h4 style={styles.pageTitleStyle}>Contact Us</h4>
            <Card style={styles.cardStyle}>
                <div style={styles.helpOuterContainerStyle}>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPhoneNumber">
                        <Form.Label column sm="2">
                            Phone Number
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control onChange={handleNumberChange} type="text" placeholder="08xx-xxxx-xxxx" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextFeedbackMessage">
                        <Form.Label column sm="2">
                            Inquiry
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                onChange={handleFeedbackChange}
                                as="textarea"
                                placeholder="Write your inquiries here ..."
                                style={{ height: '400px' }}
                            />
                        </Col>
                    </Form.Group>
                    <hr style={styles.separatorStyle}></hr>
                    <Row className="justify-content-end">
                        <Col md="2">
                            <Button onClick={sendInquiry} style={styles.buttonStyle}>
                                Send
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>
            <h4 style={styles.pageTitleStyle}>Or you can contact us at:</h4>
            <ul>
                <li>
                    <p>
                        <i class="fa fa-envelope"></i> creator@woilo.com
                    </p>
                </li>
            </ul>
        </>
    )
}

export default ContactPage