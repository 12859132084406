import React from 'react';
import { useTable } from 'react-table';
 
const ReactTable = (props) => {
  const data = props.data.dataList;
 
  const columns = props.data.columnList;

  console.log(data,columns)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data})

  return (
    <table {...getTableProps()} style={{marginTop: "10px", width: "100%"}}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: 'solid 2px #E5E5E5',
                  color: 'grey',
                  paddingBottom: "15px",
                  paddingTop: "10px",
                  fontWeight: "normal",
                  fontSize: "15px"
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      borderBottom: 'solid 1px #E5E5E5',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ReactTable;