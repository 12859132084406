import React,{ useState, useEffect } from 'react';
import { Card, ProgressBar, Row, Col, Button, Container, Spinner, Modal, OverlayTrigger, Tooltip, InputGroup, Form } from 'react-bootstrap';
import $ from 'jquery';
import UrlHouse from '../UrlHouse';
import BcaLogo from '../asset/event/bca-logo.svg';
import BniLogo from '../asset/event/bni-logo.svg';
import BriLogo from '../asset/event/bri-logo.svg';
import MandiriLogo from '../asset/event/mandiri-logo.svg';
import PermataLogo from '../asset/event/permata-logo.svg';

const EventPage = () => {
    const [ eventProgressData, setEventProgressData ] = useState({
        followerATH : null,
        followerCount: null,
        withdrawLog: [],
        progress: 0,
        lastWithdrawProgress: 0,
        lastWithdrawFollower: 0,
        withdrawPending: true
    });

    const [ modalShow, setModalShow ] = useState(false);

    const [withdrawMethod, setwithdrawMethod] = useState({
        method: "mandiri",
        logo: MandiriLogo
    })

    useEffect(() => {
        getEventProgressData();
    }, []);
    

    const styles = {
        cardStyle: {
            padding: "20px 30px",
            marginBottom: "30px",
            borderRadius: "25px"
        },

        pageTitleStyle : {
            marginBottom: "25px"
        },
    }

    const getEventProgressData = () => {
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.EVENT_PROGRESS),
            data: {a:'b'},
            dataType: "json",
            success: function (response) {
                console.log(response);
                let lastWithdrawTotal = 0
                response.withdraw_log.forEach(withdraw_data => {
                    lastWithdrawTotal += withdraw_data.withdraw_follower;
                });
                let lastWithdrawProgress = lastWithdrawTotal / 10000;
                let progress = (response.follower_ath / 10000) - lastWithdrawProgress;
                let withdrawPending;
                if(parseInt(response.withdraw_log[0].amount) > 0 && parseInt(response.withdraw_log[0].status) === 0){
                    withdrawPending = true
                }else{
                    withdrawPending = false;
                }
                setEventProgressData({
                    followerATH: response.follower_ath,
                    followerCount: response.follower_count,
                    withdrawLog: response.withdraw_log,
                    lastWithdrawFollower : lastWithdrawTotal,
                    lastWithdrawProgress: lastWithdrawProgress,
                    progress: progress,
                    withdrawPending: withdrawPending
                })
            },
            error: function(e){
                console.warn(e)
            }
        });
    }

    const onwithdrawMethodChange = (e) => {
        let method = e.target.value;
        let logo = getwithdrawMethodLogo(method);
        setwithdrawMethod({...withdrawMethod, method: method, logo: logo});
    }

    const getwithdrawMethodLogo = (method) => {
        let logo = undefined;
        switch (method.toLowerCase()) {
            case "bca":
                logo = BcaLogo
                break;
            case "bni":
                logo = BniLogo
                break;
            case "bri":
                logo = BriLogo
                break;
            case "mandiri":
                logo = MandiriLogo
                break;
            case "permata":
                logo = PermataLogo
                break;
            default:
                logo = BcaLogo
                break;
        }
        return logo;
    }

    const handleWithdraw = (amount, accountNumber) => {
        setModalShow(false);
        console.log(amount);
        console.log(withdrawMethod);
        console.log(accountNumber);
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.EVENT_WITHDRAW),
            data: {
                amount: amount,
                method: withdrawMethod.method,
                account_number: accountNumber
            },
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result === 1){
                    window.alert('Permintaan penarikan berhasil terkirim');
                    window.location.reload()
                }else{
                    window.alert('Terjadi kesalahan, mohon mencoba kembali sesaat lagi.')
                }
            },
            error: function(e) {
                console.warn(e);
                window.alert('Terjadi kesalahan, mohon mencoba kembali sesaat lagi.')
            }
        });
    }
    
    const eventProgressTooltip = (
        <Tooltip id="tooltip-disabled">Withdrawn: {eventProgressData.lastWithdrawFollower} <br></br> Current progress: <br></br> {`${eventProgressData.followerATH - eventProgressData.lastWithdrawFollower} followers | ${eventProgressData.progress}%`}</Tooltip>
    )

    return(
        <>
            <h4 style={styles.pageTitleStyle}>Event</h4>
            <Card style={styles.cardStyle}>
                <Row>
                    <Col>
                        <h5>Event progress</h5>
                        <OverlayTrigger placement="bottom" overlay={eventProgressTooltip}>
                            <ProgressBar>
                                <ProgressBar key={1} striped variant={eventProgressData.withdrawPending ? "dark" : "success"} now={eventProgressData.lastWithdrawProgress}/>
                                <ProgressBar key={2} animated now={eventProgressData.progress} 
                                    label={
                                    eventProgressData.followerATH != null ? 
                                    `${eventProgressData.followerATH - eventProgressData.lastWithdrawFollower} followers` : 
                                    <Container className="text-center">
                                        <Spinner animation="grow" size="lg"/>
                                    </Container> 
                                }/>
                                
                            </ProgressBar>
                        </OverlayTrigger>
                        <div style={{display: "inline-flex"}}>
                            <div style={{display: "inline-flex", marginTop: "10px"}}>
                                <div style={{width: "10px", height:"10px", borderRadius: "50%", backgroundColor:"black", alignSelf: "center", marginRight: "5px"}}>

                                </div>
                                <p style={{marginBottom: "0px"}}>
                                    Withdrawn
                                </p>
                            </div>
                            <div style={{display: "inline-flex", marginTop: "10px"}}>
                                <div style={{width: "10px", height:"10px", borderRadius: "50%", backgroundColor:"#0d6efd", alignSelf: "center", marginRight: "5px", marginLeft: "20px"}}>

                                </div>
                                <p style={{marginBottom: "0px"}}>
                                    Current progress
                                </p>
                            </div>
                            
                            
                        </div>
                        {/* <div>
                            <ProgressBar>
                                <ProgressBar key={1} striped variant="dark" now={20} label="200k"/>
                                <ProgressBar onMouseEnter={() => {console.log(1)}} onMouseLeave={() => {console.log(2)}} key={1} animated now={30} label="500k"/>
                            </ProgressBar>
                            <div style={{position: "relative"}}>
                                <div style={{display: "flex"}}>
                                    <div style={{position: "absolute", left: "20%"}}><p style={{marginBottom: "0px"}}>|</p>200k</div>
                                    <div style={{position: "absolute", left: "50%"}}><p style={{marginBottom: "0px"}}>|</p>500k</div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: "inline-flex", marginTop: "10px"}}>
                            <div style={{width: "10px", height:"10px", borderRadius: "50%", backgroundColor:"black", alignSelf: "center", marginRight: "5px"}}>

                            </div>
                            <p style={{marginBottom: "0px"}}>
                                Withdrawn
                            </p>
                            <div style={{width: "10px", height:"10px", borderRadius: "50%", backgroundColor:"#0d6efd", alignSelf: "center", marginRight: "5px", marginLeft: "20px"}}>
                                
                            </div>
                            <p style={{marginBottom: "0px"}}>
                                Current progress
                            </p>
                        </div> */}
                    </Col>
                    <Col>
                        <div>
                            <h5>All time high followers</h5>
                            <p>{
                                eventProgressData.followerATH != null ?
                                eventProgressData.followerATH :
                                <Container>
                                    <Spinner animation="grow" style={{marginRight: "5px"}} size="sm"/>
                                    Loading...
                                </Container> 
                            }</p>
                        </div>
                        <div>
                            <h5>Accumulated bonus</h5>
                            <p>{
                                eventProgressData.followerATH != null ? 
                                `IDR ${(eventProgressData.followerATH - eventProgressData.lastWithdrawFollower) * 100}` : 
                                <Container>
                                    <Spinner animation="grow" style={{marginRight: "5px"}} size="sm"/>
                                    Loading...
                                </Container> 
                            }</p>
                        </div>
                    </Col>
                </Row>
                    {
                        !eventProgressData.withdrawPending ?
                            <Button id="withdraw_button" onClick={() => {setModalShow(true)}}>
                                Withdraw
                            </Button>
                        :
                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Withdrawal pending, please wait until confirmed before making another withdrawal.</Tooltip>}>
                            <div style={{display: "grid", cursor: "progress"}}>
                                <Button disabled id="withdraw_button_d">
                                    Withdraw
                                </Button>
                            </div>
                        </OverlayTrigger>
                    }
                <WithdrawConfirmModal withdrawMethod={withdrawMethod} onwithdrawMethodChange={onwithdrawMethodChange} modalShow={modalShow} amount={(eventProgressData.followerATH - eventProgressData.lastWithdrawFollower) * 100} onConfirm={handleWithdraw} onCancel={() => {setModalShow(false)}}/>
            </Card>
            <div>
                <div>
                    <h5>
                        Apa yang harus dilakukan Creator?
                    </h5>
                    <p>
                        Mengumpulkan pengikut sebanyak mungkin di Woilo
                    </p>
                </div>
                <div>
                    <h5>
                        Bagaimana sistem kerjasamanya?
                    </h5>
                    <ul>
                        <li>
                            Kontrak berlaku selama 3 bulan atau setelah creator memiliki {'>'}= 1.000.000 pengikut.
                        </li>
                        <li>
                            Creator harus membuat akun Woilo.
                        </li>
                        <li>
                            Tim Woilo akan memberikan badge Akun Terverifikasi.
                        </li>
                        <li>
                            Creator mengumpulkan pengikut sebanyak mungkin di Woilo.
                        </li>
                    </ul>
                </div>
                <div>
                    <h5>
                        Keuntungan untuk Creator :
                    </h5>
                    <ul>
                        <li>
                            Creator akan mendapatkan Rp.100 untuk setiap follower yang didapat di Woilo
                        </li>
                        <li>
                            Creator mendapatkan badge Akun Terverifikasi di Woilo.
                        </li> 
                    </ul>
                </div>
                <div>
                    <h5>
                        Skema pendapatan creator :
                    </h5>
                    <ul>
                        <li>
                            1000 pengikut -{'>'} Rp.100.000
                        </li>
                        <li>
                            10.000 pengikut -{'>'} Rp.1.000.000
                        </li>
                        <li>
                            100.000 pengikut -{'>'} Rp.10.000.000
                        </li>
                        <li>
                            1.000.000 pengikut -{'>'} Rp.100.000.000
                        </li>
                    </ul>
                </div>
                <div>
                    <h5>
                        Ketentuan :
                    </h5>
                    <ul>
                        <li>
                            Creator diwajibkan untuk mematuhi <a style={{textDecoration: "none"}} href="https://woilo.com/term.html">Ketentuan Penggunaan Woilo</a> .
                        </li>
                        <li>
                            Creator dilarang untuk melakukan apapun yang dapat merusak, merugikan, atau pun mengganggu infrastruktur Woilo.
                        </li>
                        <li>
                            Creator dilarang untuk membuat akun palsu atau kosong untuk mengikuti akun Creator sendiri.
                        </li>
                        <li>
                            Creator dilarang untuk mendorong pengguna untuk membuat akun palsu atau kosong untuk mengikuti akun Creator sendiri.
                        </li>
                        <li>
                            Creator dilarang untuk mendorong pengguna untuk menghapus aplikasi Woilo dari perangkatnya.
                        </li>
                        <li>
                            Creator diizinkan untuk melakukan apapun dalam upaya mengumpulkan pengikut di Woilo selama tidak melanggar semua ketentuan dari kontrak ini dan <a style={{textDecoration: "none"}} href="https://woilo.com/term.html">Ketentuan Penggunaan Woilo</a>.
                        </li>
                        <li>
                            Tim Woilo akan memantau segala bentuk promosi atau kegiatan yang dilakukan oleh Creator dalam upaya untuk mengumpulkan pengikut di Woilo.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

const WithdrawConfirmModal = (props) => {
    const [withdrawAmount, setWithdrawAmount] = useState("0");
    const [withdrawAccountNumber, setWithdrawAccountNumber] = useState("0");

    const styles = {
        eventWithdrawModalTitleStyle: {
            marginBottom: "20px",
        },

        eventWithdrawModalTitle2Style: {
            marginBottom: "20px",
            marginTop: "30px"
        },

        eventWithdrawModalButtonStyle: {
            marginBottom: "10px",
            marginTop: "10px",
            marginRight: "20px"
        },

        eventWithdrawLogoStyle: {
            maxWidth: "100%"
        }
    }

    const handleWithdrawAmountChange = (e) => {
        let amount = e.target.value;
        setWithdrawAmount(amount);
    }

    const handleWithdrawAccountNumberChange = (e) => {
        let accountNumber = e.target.value;
        setWithdrawAccountNumber(accountNumber);
    }

    const confirmWithdraw = () => {
        const confirm = window.confirm('Kirim permintaan penarikan?');
        if (!confirm) return
        props.onConfirm(withdrawAmount, withdrawAccountNumber)
    }

    return (
        <Modal
            show={props.modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Withdraw
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 style={styles.eventWithdrawModalTitleStyle}>Jumlah penarikan</h5>
                    <InputGroup classNameName="mb-2">
                        <InputGroup.Text>Rp.</InputGroup.Text>
                        <Form.Control onChange={handleWithdrawAmountChange} id="eventWithdrawAmount" type="number" placeholder="xxx.xxx" step="10000" min="10000"/>
                    </InputGroup>
                <h5 style={styles.eventWithdrawModalTitle2Style}>Metode penarikan</h5>
                <Row>
                    <Col md="1" style={{alignSelf: "center"}}>
                        <img id="eventWithdrawLogo" src={props.withdrawMethod.logo} style={styles.eventWithdrawLogoStyle} alt="eventWithdrawLogo"></img>
                    </Col>
                    <Col>
                        <Form.Select onChange={props.onwithdrawMethodChange} id="eventWithdrawMethod" name="eventWithdrawMethod" aria-label="eventWithdrawMethodSelect">
                            <option selected value="mandiri">Mandiri</option>
                            <option value="bca">BCA</option>
                            <option value="bni">BNI</option>
                            <option value="bri">BRI</option>
                            <option value="permata">Permata</option>
                        </Form.Select>
                    </Col>
                </Row>
                <InputGroup style={{marginTop: "10px"}} classNameName="mb-2">
                    <InputGroup.Text>Account number</InputGroup.Text>
                    <Form.Control onChange={handleWithdrawAccountNumberChange} id="eventWithdrawAmount"/>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button onClick={confirmWithdraw}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EventPage;