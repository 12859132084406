import React, { useEffect, useRef, useState } from 'react';
import NoData from '../asset/no_data.svg';
import Logo from '../asset/logo_2.png';
import { Button, Card, Container, Spinner, Overlay, Popover } from 'react-bootstrap';
import $ from 'jquery';
import ReactTable from '../components/ReactTable';
import SideDrawer from '../components/SideDrawer';
import UrlHouse from '../UrlHouse';

const ContentLibraryPage = (props) => {

    const [ activeFilter,setActiveFilter ] = useState("000");
    const [ postListLoaded, setPostListLoaded ] = useState(false);

    const [ popoverTarget, setPopoverTarget ] = useState(null);
    const [ popoverOpenId, setPopoverOpenId ] = useState("");
    const [ isPopoverOpen, setIsPopoverOpen ] = useState(false);
    const [ postDetailOpen, setPostDetailOpen ] = useState(false);
    const ref = useRef(null);
    useOutsideAlerter(ref);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsPopoverOpen(false);
                }
            }
    
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const [ fullPostList, setFullPostList] = useState();

    const [ postList, setPostList] = useState({
        dataList: [
            
        ],
        columnList: [
            {
                Header: 'Post',
                accessor: 'postPreview',
            },
            {
                Header: 'Post Type',
                accessor: 'postType',
            },
            {
                Header: 'Date',
                accessor: 'postDate',
            },
            {
                Header: 'Likes',
                accessor: 'postLikes'
            },
            {
                Header: 'Comments',
                accessor: 'postComments'
            },
            {
                Header: '',
                accessor: 'postOptions'
            }
        ]
    })

    useEffect(() => {
        $('#000').css('background-color','lightgrey');
        $('#popover_button').css('pointer-event','none');
        loadPostList();
    }, [])

    const styles = {
        cardStyle: {
            padding: "20px 30px",
            marginBottom: "30px",
            borderRadius: "25px"
        },

        pageTitleStyle : {
            marginBottom: "25px"
        },

        postFilterButtonContainerStyle : {
            display: "inline-flex"
        },

        postFilterButtonStyle: {
            margin: "5px",
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer"
        },

        postListTableContainerStyle: {
            marginTop: "10px",
            width: "100%",
            maxHeight: "60vh",
            overflow: "scroll"
        },

        backDropStyle : {
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "100",
            top: "0",
            right: "0"
        },

        popoverContainerStyle : {
            width: "200px",
            maxWidth : "200px",
        },

        popoverPostTypeContainerStyle: {
            paddingTop: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
            textAlign: "center",
            borderRadius: "10px",
            marginTop: "10px",
            border: "solid 1px lightgrey"
        },
    }

    const handlePopoverClick = (e) => {
        setIsPopoverOpen(!isPopoverOpen);
        setPopoverTarget(e.target);
        setPopoverOpenId(e.target.id);
    }

    const handlePostDetailClick = () => {
        setIsPopoverOpen(false);
        setPostDetailOpen(true);
        props.onPostDetailOpen();
    }

    const popover = (
        <>
            <div style={styles.popoverContainerStyle}>
                <div onClick={handlePostDetailClick} className="postDetails" id={`detail_${popoverOpenId}`} onMouseEnter={(e) => {$(`#${e.target.id}`).css('background-color','lightgrey')}} onMouseLeave={(e) => {$(`#${e.target.id}`).css('background-color','transparent')}} style={styles.popoverPostTypeContainerStyle}>
                    Details
                </div>
                <div className="postArchive" id={`archive_${popoverOpenId}`} onClick={(e) => {console.log(e.target.id)}} onMouseEnter={(e) => {$(`#${e.target.id}`).css('background-color','lightgrey')}} onMouseLeave={(e) => {$(`#${e.target.id}`).css('background-color','transparent')}} style={styles.popoverPostTypeContainerStyle}>
                    Archive post
                </div>
                <div className="postDelete" id={`delete_${popoverOpenId}`} onClick={(e) => {console.log(e.target.id)}} onMouseEnter={(e) => {$(`#${e.target.id}`).css('background-color','lightgrey')}} onMouseLeave={(e) => {$(`#${e.target.id}`).css('background-color','transparent')}} style={styles.popoverPostTypeContainerStyle}>
                    Delete Post
                </div>
            </div>
            
        </>
                
    );

    const loadPostList = () => {
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.LOAD_POSTS),
            data: {
                a : 'b'
            },
            dataType: "json",
            success: function (response) {
                let postDataList = []
                response.post_data_list.forEach(postData => {
                    let postDisplay = postData.post_type === "101" || postData.post_type === "102" ? postData.post_picture : JSON.parse(postData.post_picture)[0];
                    const postPreview = <div>
                        <div className="d-flex" style={{marginRight: "-100px"}}>
                            <img style={{width: "100px", height: "100px", alignSelf: "center"}} src={`https://sestyc.com/sestyc/content_file/${postDisplay}`} alt="postPhoto"></img>
                            <div style={{marginLeft: "20px",maxWidth: "300px", maxHeight: "100px",overflow: "scroll",fontWeight: "600"}}>
                                <p>{postData.post_description}</p>
                            </div>
                        </div>
                    </div>
                    let time_stamp = postData.time_stamp;
                    let time_stamp_final = time_stamp.slice(6,8) + '/' +time_stamp.slice(4,6)+ '/' + time_stamp.slice(0,4) + " " + time_stamp.slice(8,10) + ":" + time_stamp.slice(10,12);
                    const postListEntry = 
                        {
                            postPreview: postPreview,
                            postType: postData.post_type === "101" ? "Photo" : postData.post_type === "102" ? "Video" : "Carousel",
                            postDate: time_stamp_final,
                            postLikes: postData.like_count,
                            postComments: postData.comment_count,
                            postOptions: 
                                <Button onClick={handlePopoverClick.bind(this)} id={postData.post_id}  style={{backgroundColor: "transparent", borderColor: "transparent", color: "black"}}><i id={postData.post_id} class="fa fa-ellipsis-v" aria-hidden="true"></i></Button>
                        }
                    postDataList.push(postListEntry)
                });
                setPostList({...postList, dataList : postDataList})
                setFullPostList(postDataList);
                setPostListLoaded(true);
                $('#popover_button').css('pointer-event','default');
            },
            error: function(e){
                console.warn(e)
            }
        });
    }

    const onFilterButtonClick = (e) => {
        let selected = e.target.id;
        $('.filterButton').css("background-color","transparent");
        $(`#${selected}`).css("background-color","lightgrey");
        setActiveFilter(selected);
        if(selected === "000"){
            setPostList({...postList, dataList : fullPostList});
            return
        }
        let filter = selected === "101" ? "Photo" : selected === "102" ? "Video" : "Carousel";
        let filteredPostData = [];
        fullPostList.forEach((postData) => {
            if(postData.postType === filter){
                filteredPostData.push(postData)
            }
        })
        console.log(filteredPostData);
        setPostList({...postList, dataList : filteredPostData});
    }

    return(
        <>  
            <SideDrawer postDetailId={popoverOpenId} drawerType={postDetailOpen ? 1 : 0} isOpen={props.drawerOpen} postTypeCreate={props.postTypeCreate}/>
            { props.drawerOpen ? <div onClick={props.onDrawerClose} style={styles.backDropStyle}></div> : null}
            <h4 style={styles.pageTitleStyle}>Content library</h4>
            <Card style={styles.cardStyle}>
                <div style={styles.postFilterButtonContainerStyle}>
                    <div style={styles.postFilterButtonStyle} className="filterButton" id="000" onClick={onFilterButtonClick}>
                        All Content
                    </div>
                    <div style={styles.postFilterButtonStyle} className="filterButton" id="101" onClick={onFilterButtonClick}>
                        Photos
                    </div>
                    <div style={styles.postFilterButtonStyle} className="filterButton" id="102" onClick={onFilterButtonClick}>
                        Videos
                    </div>
                    <div style={styles.postFilterButtonStyle} className="filterButton" id="103" onClick={onFilterButtonClick}>
                        Carousels
                    </div>
                    <div ref={ref}>
                    <Overlay
                                show={isPopoverOpen}
                                target={popoverTarget}
                                placement="left"
                                container={ref}
                                containerPadding={20}
                            >
                                <Popover id="popover-contained">
                                    <Popover.Body>
                                        {popover}
                                    </Popover.Body>
                                </Popover>
                            </Overlay>
                    </div>
                </div>
                <hr></hr>
                <div style={styles.postListTableContainerStyle}>
                    {
                    !postListLoaded ? 
                        <Container className="text-center">
                            <Spinner animation="grow" size="lg"/>
                        </Container> 
                    :postList.dataList.length === 0 ?
                        <div className="text-center">
                            <img style={styles.noDataIllustrationStyle} src={NoData} alt="noData"></img>
                            <h5 style={{color: "grey"}}>No Data</h5>
                        </div>
                    :
                    <ReactTable data={postList}/>
                    }
                </div>
            </Card>
        </>
    )
}

export default ContentLibraryPage;