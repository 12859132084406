export default class UrlHouse {
    baseUrl = "https://creator.woilo.com/apis/";

    api_list = {
        "loginScript" : "creator_login_script.php",
        "logoutScript" : "creator_logout_script.php",
        "checkSessionScript" : "check_session.php",
        "loadPostScript" : "creator_load_posts.php",
        "uploadImageMediaScript" : "creator_upload_image_media.php",
        "uploadVideoMediaScript" : "creator_upload_video_media.php",
        "savePostDraftScript" : "creator_save_post_draft.php",
        "savePostScheduleScript" : "creator_save_post_schedule.php",
        "publishPostScript" : "creator_publish_post.php",
        "editPostScript" : "creator_edit_post.php",
        "postDetailScript" : "creator_post_detail.php",
        "loadInsightScript" : "creator_load_insight.php",
        "eventProgressScript" : "creator_event_progress.php",
        "eventWithdrawScript" : "creator_event_withdraw.php",
        "accountStatusScript" : "get_account_status.php",
        "submitMessageScript" : "creator_submit_message.php"
    }

    static LOGIN_SCRIPT = "loginScript";
    static LOGOUT_SCRIPT = "logoutScript";
    static CHECK_SESSION = "checkSessionScript";
    static LOAD_POSTS = "loadPostScript";
    static UPLOAD_IMAGE_MEDIA = "uploadImageMediaScript";
    static UPLOAD_VIDEO_MEDIA = "uploadVideoMediaScript";
    static SAVE_POST_DRAFT = "savePostDraftScript";
    static SAVE_POST_SCHEDULE = "savePostScheduleScript";
    static PUBLISH_POST = "publishPostScript";
    static EDIT_POST = "editPostScript";
    static POST_DETAIL = "postDetailScript";
    static LOAD_INSIGHT = "loadInsightScript";
    static EVENT_PROGRESS = "eventProgressScript";
    static EVENT_WITHDRAW = "eventWithdrawScript";
    static ACCOUNT_STATUS = "accountStatusScript";
    static SUBMIT_MESSAGE = "submitMessageScript";
    
    getApiUrl(key){
        return this.baseUrl + this.api_list[key];
    }
    
}