import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DashboardNavigation from "../components/DashboardNavigation";
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import UrlHouse from "../UrlHouse";
import $ from 'jquery';

const CreatorDashboardPage = (props) => {
    const [ accountStatus, setAccountStatus ] = useState(0);

    useEffect(() => {
      getAccountStatus();
      //checkSession();
    },[]);
    
    const navigate = useNavigate();

    const styles = {
        dashboardContentContainerStyle : {
            paddingTop: "7.5%",
            paddingLeft: "20%",
            height: "100%",
            width: "100%",
            backgroundColor: "#F2F2F2"
        }
    }

    const checkSession = () => {
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.CHECK_SESSION),
            data: {a:'b'},
            dataType: "json",
            success: function (response) {
                if(response.result === 0){
                    window.alert('Tolong login kembali.')
                    navigate('/');
                }
            },
            error: function(e){
                console.warn(e);
            }
        });
    }

    const getAccountStatus = () => {
        $.ajax({
            type: "POST",
            url: new UrlHouse().getApiUrl(UrlHouse.ACCOUNT_STATUS),
            data: {a: 'b'},
            dataType: "json",
            success: function (response) {
                console.log(response);
                let accountStatus = response.account_status;
                setAccountStatus(Number(accountStatus));
            },
            error: function(e) {
                console.warn(e);
            }
        });
    }

    return(
        <>
            <DashboardNavigation accountStatus={accountStatus} onDrawerOpen={props.onDrawerOpen} onPostTypeChange={props.onPostTypeChange}/>
            <div style={styles.dashboardContentContainerStyle}>
                <Container>
                    <Outlet/>
                </Container>
            </div>
        </>
    )
}

export default CreatorDashboardPage;